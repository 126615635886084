
.main-wrapper{
    height: 100%;
    .no-data-icon{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 10%;
        justify-content: center;
    }
    .no-data-font{
        font-weight: 600;
        font-size: 16px;
    }
}
